// ********************** Initializations **********************
const searchBox = document.getElementById("searchBox");
const searchToggle = document.getElementById("searchToggle");


// ********************** Slider Computation **********************
// -- Automatic slideshow Implementation
let slideIndex = 0;
let autoSliderflag = true;

// -- Enable the slider in the page
showSlides();
// let timeoutHandler = setTimeout(showSlides, 5000);

// --
function plusSlides(n){
  // --
  slideIndex += n;
  autoSliderflag = false;
  // --
  // clearTimeout(timeoutHandler);
  showSlides();
}

// function currentSlide(n){
//   // alert(slideIndex + " : " + n);
//   // --
//   slideIndex = n;
//   autoSliderflag = false;
//   // --
//   clearTimeout(timeoutHandler);
//   showSlides();
// }

function showSlides(){
  let i;
  let slides = document.getElementsByClassName("myTransitions");
  let dots = document.getElementsByClassName("dot");

  // -- Disable display for sliders and dot transitions
  for (i = 0; i < slides.length; i++){
    slides[i].style.display = "none";
  }
  for (i = 0; i < dots.length; i++){
    dots[i].className = dots[i].className.replace(" active", "");
  }

  // -- Increment slide index
  if (autoSliderflag == true) {slideIndex++}
  if (slideIndex > slides.length) {
    slideIndex = 1
  }
  else if(slideIndex < 1){
    slideIndex = slides.length;
  }

  // -- Enable display for sliders and dot transitions
  slides[slideIndex-1].style.display = "block";
  dots[slideIndex-1].className += " active";

  // -- Initialize timeout [Known bug -> Synchronization issue]
  if(autoSliderflag == false)
  { 
    // clearTimeout(timeoutHandler);
    autoSliderflag = true; 
  }

  // --
  // setTimeout(showSlides, 5000)
}

// ********************** Listeners **********************
previousToggle.addEventListener('click', (e) => {
  plusSlides(-1);
});
nextToggle.addEventListener('click', (e) => {
  plusSlides(1);
});
searchToggle.addEventListener('click', (e) => {
  e.preventDefault();
  alert(searchBox.value);
});
const collapsibles = document.querySelectorAll(".collapsible");
collapsibles.forEach((item) =>
  item.addEventListener("click", function () {
      this.classList.toggle("collapsible--expanded");
  })
);
const gridCollapsibles = document.querySelectorAll(".grid__body--items");
gridCollapsibles.forEach((item) =>
  item.addEventListener("click", function() {
      this.classList.toggle("grid__collapsible");
  })
);
const articleCollapsible = document.querySelectorAll(".article--context");
articleCollapsible.forEach((item) =>
  item.addEventListener("click", function () {
    this.classList.toggle("article__expanded");
  }) 
);